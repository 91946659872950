



























import { Vue, Component, Prop } from 'vue-property-decorator';
import { H3Input } from '@h3/thinking-ui';

@Component({
  name: 'CommonInput',
  components: {
    H3Input,
  },
})
export default class CommonInput extends Vue {
  @Prop()
  value!: string;

  // 提示文本
  @Prop({
    default: '请输入',
  })
  placeholder!: string;

  // 原生类型
  @Prop({
    default: 'text',
  })
  type!: string;

  // 默认提示语
  @Prop({
    default: '',
  })
  tip!: string;

  // 错误提示语
  @Prop({
    default: '',
  })
  errorTip!: string;

  // 是否可以切换明文密码
  @Prop({
    default: false,
  })
  plaintext!: boolean;

  // 是否可以切换明文密码
  @Prop({
    default: true,
  })
  required!: boolean;

  // 自定义过滤函数
  @Prop()
  format!: Function;

  // 是否显示区域
  @Prop()
  showArea?: boolean;

  // 主样式名
  prefixCls: string = 'common-input';

  // 双向绑定值
  get innerValue () {
    return this.value;
  }

  set innerValue (val) {
    let v = val;
    this.$emit('input', v);
    this.$nextTick(() => {
      if (this.format) {
        v = this.format(val);
      }
      this.$emit('input', v);
    });
  }

  /**
   * 获取焦事件
   */
  focus (e) {
    this.$emit('focus', e);
  }

  /**
   * 失去焦事件
   */
  blur (e) {
    this.$emit('blur', e);
  }

  /**
   * input事件
   */
  input (e) {
    this.$emit('input', e);
  }

  async mounted () {}
}
